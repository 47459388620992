import markerSDK from '@marker.io/browser'
import { captureException } from '@sentry/vue'

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig()
  try {
    const marker = await markerSDK.loadWidget({
      project: config.public.markerProjectId,
      ssr: {
        renderDelay: 1000, // ms
      },
      silent: !config.public.isStaging,
    })
    if (config.public.isStaging) {
      marker.show()
    }
  } catch (err) {
    captureException(err)
    console.error(err)
  }
})
