import anime from 'animejs'

import type { TransitionProps } from '#app/compat/capi'

export const PAGE_TRANSITION_OVERLAY_CLASS = 'page-transition-overlay'

export const disablePageTransition = ref(false)

export const circleOverlayTopTransition = {
  mode: 'out-in',
  css: false,
  name: 'circleOverlayTopTransition',
  onLeave: (_, done) => {
    // console.log('onLeave')
    if (disablePageTransition.value) return done()
    anime({
      targets: `.${PAGE_TRANSITION_OVERLAY_CLASS as string}`,
      width: [0, '300vmax'],
      height: [0, '300vmax'],
      duration: 500,
      easing: 'easeInOutQuad',
      complete: () => done(),
    })
  },
  onEnter: (_, done) => {
    // console.log('onEnter')
    if (disablePageTransition.value) return done()
    anime({
      targets: `.${PAGE_TRANSITION_OVERLAY_CLASS as string}`,
      width: ['300vmax', 0],
      height: ['300vmax', 0],
      easing: 'easeInOutQuad',
      duration: 500,
      complete: () => done(),
    })
  },
} satisfies TransitionProps
