<script lang="ts" setup>
import { projectKey } from '~/types/inject'

const mobileMenuOpen = ref(false)
const menuItems = [
  {
    title: 'Agentur',
    link: '/agentur',
  },
  {
    title: 'Projekte',
    link: '/projekte',
  },
  {
    title: 'Kontakt',
    link: '/kontakt',
  },
]

const project = inject(projectKey)

const tw = String.raw
const HEADER_CLASS = tw`pointer-events-none fixed top-0 z-50 flex w-full items-center p-8`
const NAV_CLASS = tw`pointer-events-auto mr-8 flex gap-16 text-lg max-md:hidden`
const NAVLINK_CLASS = tw`-mt-1 px-1 pt-1 font-mono`
</script>

<template>
  <div class="flex flex-col items-center">
    <header
      :class="HEADER_CLASS"
      class="w-full max-w-container justify-between text-white mix-blend-difference"
      :style="{
        '--color-primary': project?.value.primary_color ?? 'black',
        '--color-secondary': project?.value.secondary_color ?? 'black',
      }"
    >
      <ElbworxLink href="/" class="pointer-events-auto">
        <LogoImage class="w-28" />
      </ElbworxLink>
      <nav :class="NAV_CLASS">
        <span v-for="item of menuItems" :key="item.link" :class="NAVLINK_CLASS">
          {{ item.title }}
        </span>
      </nav>

      <button
        class="pointer-events-auto relative flex gap-8 overflow-hidden px-1 pt-1 font-mono text-lg md:hidden"
        @click="() => (mobileMenuOpen = true)"
      >
        menü
      </button>
      <MobileNav
        v-model:open="mobileMenuOpen"
        class="pointer-events-auto md:hidden"
        :menu-items="menuItems"
      />
    </header>
    <div :class="HEADER_CLASS" class="w-full max-w-container justify-end">
      <nav :class="NAV_CLASS">
        <ElbworxLink
          v-for="item of menuItems"
          :key="item.link"
          :href="item.link"
          :class="NAVLINK_CLASS"
          class="group relative overflow-hidden"
        >
          <HoverFillButton class="bg-elbworx-yellow opacity-0 group-hover:opacity-100" />

          <span class="relative opacity-0 transition-opacity group-hover:opacity-100">
            {{ item.title }}
          </span>
        </ElbworxLink>
      </nav>
    </div>
  </div>
</template>
