<template>
  <svg viewBox="0 0 96 24" style="enable-background: new 0 0 96 24">
    <g>
      <path
        class="fill-current transition-all"
        d="M9.7,15.4l4.6-7H4.6l-4.6,7l3.4,7h4l-3.4-7H9.7z M18.3,22.3l1.8-20.9h-4l-1.8,20.9H18.3z M31.9,15.4
		c-0.2,1.8-1.7,3.2-3.5,3.2c-1.8,0-3.1-1.4-2.9-3.2c0.2-1.8,1.7-3.2,3.5-3.2C30.8,12.2,32.1,13.6,31.9,15.4 M35.9,15.4
		c0.3-4-2.6-7.2-6.6-7.2c-1.2,0-2.3,0.3-3.3,0.8l0.7-7.5h-4l-1.8,20.9h4l0-0.5c0.9,0.5,2,0.8,3.2,0.8C32.1,22.6,35.6,19.4,35.9,15.4
		 M53.8,22.3l6-13.9h-4l-3.5,8.1l-2.1-8.1h-4l-3.5,8.1l-2.1-8.1h-4l3.6,13.9h4l3.5-8.1l2.1,8.1H53.8z M69.5,15.4
		c-0.2,1.8-1.7,3.2-3.5,3.2c-1.8,0-3.1-1.4-2.9-3.2c0.2-1.8,1.7-3.2,3.5-3.2C68.3,12.2,69.6,13.6,69.5,15.4 M73.5,15.4
		c0.3-4-2.6-7.2-6.6-7.2s-7.5,3.2-7.8,7.2c-0.3,4,2.6,7.2,6.6,7.2C69.6,22.6,73.1,19.4,73.5,15.4 M82.2,12.4l0.4-4
		c-3.9,0-7.2,3.1-7.6,7l-0.6,7h4l0.6-7C79.1,13.7,80.6,12.4,82.2,12.4 M96,8.4h-4l-2.3,3.5L88,8.4h-4l3.4,7l-4.6,7h4l2.3-3.5
		l1.7,3.5h4l-3.4-7L96,8.4z"
      />
    </g>
  </svg>
</template>
