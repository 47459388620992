<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
    <path
      id="Pfad_1608"
      data-name="Pfad 1608"
      d="M20.5,0A20.5,20.5,0,1,0,41,20.5,20.5,20.5,0,0,0,20.5,0ZM14.543,30.99H9.55V15.97h4.993Zm-2.5-17.072h-.033a2.6,2.6,0,1,1,.066-5.19,2.6,2.6,0,1,1-.033,5.19Zm20.5,17.072H27.553V22.955c0-2.019-.723-3.4-2.529-3.4a2.733,2.733,0,0,0-2.562,1.826A3.418,3.418,0,0,0,22.3,22.6V30.99H17.306s.065-13.611,0-15.021H22.3V18.1a4.957,4.957,0,0,1,4.5-2.479c3.285,0,5.748,2.147,5.748,6.76Z"
      fill="currentColor"
    />
  </svg>
</template>
