<script lang="ts">
import { FacebookIcon, InstagramIcon, LinkedinIcon } from '#components'

export const socialLinks = [
  {
    href: 'https://www.linkedin.com/showcase/elbworx/',
    icon: LinkedinIcon,
  },
  {
    href: 'https://www.instagram.com/elbworx/',
    icon: InstagramIcon,
  },
  {
    href: 'https://www.facebook.com/elbworx/',
    icon: FacebookIcon,
  },
]
</script>

<script lang="ts" setup>
const links = [
  {
    href: '/impressum',
    text: 'Impressum',
  },
  {
    href: '/datenschutz',
    text: 'Datenschutz',
  },
]

const year = new Date().getFullYear()
</script>

<template>
  <Padding class="flex justify-center">
    <Container>
      <footer class="sticky top-[100vh] flex flex-col gap-6 py-8 md:py-14">
        <div class="flex justify-between">
          <Image src="/favicon.png" class="-mx-1.5 h-16" />
          <div class="flex gap-7 py-3 md:gap-12">
            <ElbworxLink v-for="link of socialLinks" :key="link.href" :href="link.href">
              <component :is="link.icon" class="h-9 w-9 md:h-12 md:w-12" />
            </ElbworxLink>
          </div>
        </div>
        <div class="flex justify-between font-mono font-semibold max-sm:text-xs">
          <p class="">{{ year }} elbworx</p>
          <div class="flex gap-4 md:gap-16">
            <ElbworxLink v-for="link of links" :key="link.href" :href="link.href">
              {{ link.text }}
            </ElbworxLink>
          </div>
        </div>
      </footer>
    </Container>
  </Padding>
</template>
