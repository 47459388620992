<template>
  <svg
    id="Social_Media"
    data-name="Social Media"
    xmlns="http://www.w3.org/2000/svg"
    width="40.977"
    height="40.977"
    viewBox="0 0 40.977 40.977"
  >
    <g id="Gruppe_346" data-name="Gruppe 346">
      <path
        id="Pfad_158"
        data-name="Pfad 158"
        d="M214.843,210.922A3.922,3.922,0,1,1,210.922,207,3.922,3.922,0,0,1,214.843,210.922Z"
        transform="translate(-190.433 -190.433)"
        fill="currentColor"
      />
      <path
        id="Pfad_159"
        data-name="Pfad 159"
        d="M154.3,138.194a3.9,3.9,0,0,0-2.233-2.233,6.51,6.51,0,0,0-2.184-.4c-1.241-.057-1.613-.069-4.754-.069s-3.514.012-4.754.068a6.514,6.514,0,0,0-2.184.405,3.9,3.9,0,0,0-2.233,2.233,6.512,6.512,0,0,0-.4,2.185c-.057,1.24-.069,1.612-.069,4.754s.012,3.513.069,4.754a6.51,6.51,0,0,0,.4,2.184,3.9,3.9,0,0,0,2.233,2.233,6.5,6.5,0,0,0,2.185.405c1.241.057,1.612.069,4.754.069s3.514-.012,4.754-.069a6.5,6.5,0,0,0,2.185-.405,3.9,3.9,0,0,0,2.233-2.233,6.516,6.516,0,0,0,.405-2.184c.057-1.241.068-1.613.068-4.754s-.012-3.514-.068-4.754A6.5,6.5,0,0,0,154.3,138.194Zm-9.171,12.98a6.041,6.041,0,1,1,6.041-6.041A6.041,6.041,0,0,1,145.129,151.174Zm6.28-10.909a1.412,1.412,0,1,1,1.412-1.412A1.412,1.412,0,0,1,151.409,140.265Z"
        transform="translate(-124.641 -124.645)"
        fill="currentColor"
      />
      <path
        id="Pfad_160"
        data-name="Pfad 160"
        d="M20.488,0A20.488,20.488,0,1,0,40.977,20.488,20.491,20.491,0,0,0,20.488,0ZM32.182,25.339a8.631,8.631,0,0,1-.547,2.856,6.015,6.015,0,0,1-3.441,3.441,8.638,8.638,0,0,1-2.856.547c-1.255.057-1.655.071-4.85.071s-3.6-.014-4.851-.071a8.637,8.637,0,0,1-2.856-.547,6.016,6.016,0,0,1-3.441-3.441,8.629,8.629,0,0,1-.547-2.856c-.058-1.255-.071-1.656-.071-4.851s.013-3.6.071-4.85a8.632,8.632,0,0,1,.546-2.856,6.02,6.02,0,0,1,3.441-3.441,8.639,8.639,0,0,1,2.856-.547c1.255-.057,1.655-.071,4.85-.071s3.6.014,4.85.071a8.641,8.641,0,0,1,2.856.546,6.017,6.017,0,0,1,3.441,3.441,8.631,8.631,0,0,1,.547,2.856c.057,1.255.071,1.655.071,4.85S32.24,24.084,32.182,25.339Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
