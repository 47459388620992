<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
    <path
      id="Pfad_161"
      data-name="Pfad 161"
      d="M41,20.5A20.5,20.5,0,1,0,20.5,41c.12,0,.24,0,.36-.008V25.04h-4.4V19.907h4.4v-3.78c0-4.38,2.675-6.767,6.582-6.767a35.777,35.777,0,0,1,3.948.2v4.58H28.7c-2.122,0-2.538,1.009-2.538,2.49V19.9h5.085l-.665,5.133h-4.42V40.207A20.506,20.506,0,0,0,41,20.5Z"
      fill="currentColor"
    />
  </svg>
</template>
