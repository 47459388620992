import revive_payload_client_Y0jp3pN2UH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@9.14.0_jiti@2.4._q6xfqvd25hommcs4zi52vdplwq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VD0TxZEjur from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@9.14.0_jiti@2.4._q6xfqvd25hommcs4zi52vdplwq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yg8osrVjdp from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@9.14.0_jiti@2.4._q6xfqvd25hommcs4zi52vdplwq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TmWmd5f1hV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@20.17.6_sass@1.7_2zcjkvkzkxaprbcxqoimwsoclu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import sentry_client_Ub7jFNgAqV from "/opt/buildhome/repo/modules/sentry/runtime/sentry.client.ts";
import payload_client_v0C0e73lyc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@9.14.0_jiti@2.4._q6xfqvd25hommcs4zi52vdplwq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_F7bUbbwnHq from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@9.14.0_jiti@2.4._q6xfqvd25hommcs4zi52vdplwq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nq01Dl1Kxk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@9.14.0_jiti@2.4._q6xfqvd25hommcs4zi52vdplwq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SPSD6kfwSS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@9.14.0_jiti@2.4._q6xfqvd25hommcs4zi52vdplwq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_suLRqKE0CS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@9.14.0_jiti@2.4._q6xfqvd25hommcs4zi52vdplwq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_3IIKOMPAGx from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@20.17._h2v3yhol57nvy3ua7xzidq64ku/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_EkEJT1Z5X8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@20.17.6_sass@_7jx6fer4kpoi4si5k7uyupwbni/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_4L4yXTjTEI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@20.17.6_sass@_7jx6fer4kpoi4si5k7uyupwbni/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import umami_iN1DWXMIUx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-umami@2.6.7/node_modules/nuxt-umami/plugins/umami.ts";
import marker_io_client_3vX6FuptAi from "/opt/buildhome/repo/plugins/marker-io.client.ts";
import defaults_X4cZ1dWnJl from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@20.17._h2v3yhol57nvy3ua7xzidq64ku/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_Y0jp3pN2UH,
  unhead_VD0TxZEjur,
  router_yg8osrVjdp,
  _0_siteConfig_TmWmd5f1hV,
  sentry_client_Ub7jFNgAqV,
  payload_client_v0C0e73lyc,
  navigation_repaint_client_F7bUbbwnHq,
  check_outdated_build_client_nq01Dl1Kxk,
  chunk_reload_client_SPSD6kfwSS,
  components_plugin_KR1HBZs4kY,
  prefetch_client_suLRqKE0CS,
  titles_3IIKOMPAGx,
  siteConfig_EkEJT1Z5X8,
  inferSeoMetaPlugin_4L4yXTjTEI,
  umami_iN1DWXMIUx,
  marker_io_client_3vX6FuptAi,
  defaults_X4cZ1dWnJl
]