<template>
  <h1
    class="relative text-7xl font-black italic sm:text-9xl md:text-[10rem] 2xl:text-[12rem]"
    :style="{
      '--outline-color': 'currentColor',
    }"
  >
    <slot />
    <span
      aria-hidden
      class="text-outline absolute inset-0 h-full w-full -translate-y-[0.025em] translate-x-[0.025em] text-elbworx-cyan"
    >
      <slot />
    </span>
  </h1>
</template>
