
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "umami": {
    "id": "ba56270a-7b9f-4c84-bea8-adbc38ed6b6e",
    "host": "https://umami.falcondev.de",
    "version": 2,
    "domains": "",
    "debug": false,
    "autoTrack": true,
    "useDirective": true,
    "customEndpoint": "/",
    "ignoreLocalhost": true
  },
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-umami@2.6.7/node_modules/nuxt-umami/app.config.ts"
import cfg1 from "/opt/buildhome/repo/modules/sentry/runtime/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, inlineConfig)
