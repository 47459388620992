/* eslint-disable ts/no-unsafe-assignment */
import { type VCard, defineVCard } from '~/types/vcard'

import { FacebookIcon, InstagramIcon, LinkedinIcon } from '#components'

const elbworxSocials = [
  { href: 'https://www.instagram.com/elbworx/', icon: InstagramIcon },
  { href: 'https://www.facebook.com/elbworx/', icon: FacebookIcon },
] as const
const elbworxLinkedIn = {
  href: 'https://de.linkedin.com/company/elbworx-com',
  icon: LinkedinIcon,
}

const dMenzel = defineVCard({
  name: 'Daniel Menzel',
  alias: 'd.menzel',
  title: 'Geschäftsführer',
  tel: { href: 'tel:+4935185033642', text: '0351 850 33 64-2' },
  socials: [
    { href: 'https://www.linkedin.com/in/daniel-menzel-9765bb182/', icon: LinkedinIcon },
    ...elbworxSocials,
  ],
  links: {
    appointment:
      'https://outlook.office.com/bookwithme/user/eb509e8dff7c4b9fb3b6dc8e7f2b98a6@elbworx.com?anonymous&ep=plink',
    teams: 'https://teams.microsoft.com/l/chat/0/0?users=d.menzel@elbworx.com',
  },
})

const eRiedel = defineVCard({
  name: 'Erik Riedel',
  alias: 'e.riedel',
  title: 'Media Designer',
  tel: { href: 'tel:+4935185033646', text: '0351 850 33 64-6' },
  socials: [elbworxLinkedIn, ...elbworxSocials],
})

const lSteiner = defineVCard({
  name: 'Lisa Steiner',
  alias: 'l.steiner',
  title: 'Media Designer',
  tel: { href: 'tel:+4935185033647', text: '0351 850 33 64-7' },
  socials: [elbworxLinkedIn, ...elbworxSocials],
})

const mJungwirth = defineVCard({
  name: 'Martin Jungwirth',
  alias: 'm.jungwirth',
  title: 'Media Designer',
  tel: { href: 'tel:+4935185033644', text: '0351 850 33 64-4' },
  socials: [elbworxLinkedIn, ...elbworxSocials],
})

const sGoepfert = defineVCard({
  name: 'Stefanie Göpfert',
  alias: 's.goepfert',
  title: 'Media Designer',
  tel: { href: 'tel:+4935185033645', text: '0351 850 33 64-5' },
  socials: [
    { href: 'https://www.linkedin.com/in/s-goepfert', icon: LinkedinIcon },
    ...elbworxSocials,
  ],
})

const sHoelzel = defineVCard({
  name: 'Sophie Hölzel',
  alias: 's.hoelzel',
  title: 'Junior Art Director',
  tel: { href: 'tel:+4935185033643', text: '0351 850 33 64-3' },
  socials: [
    { href: 'https://www.linkedin.com/in/s-hoelzel', icon: LinkedinIcon },
    ...elbworxSocials,
  ],
})

const tFilip = defineVCard({
  name: 'Thomas Filip',
  alias: 't.filip',
  title: 'Creative Director',
  tel: { href: 'tel:+4935185033641', text: '0351 850 33 64-1' },
  socials: [elbworxLinkedIn, ...elbworxSocials],
})

export const VCARDS = {
  'd.menzel': dMenzel,
  'e.riedel': eRiedel,
  'l.steiner': lSteiner,
  'm.jungwirth': mJungwirth,
  's.goepfert': sGoepfert,
  's.hoelzel': sHoelzel,
  't.filip': tFilip,
} as const satisfies Record<string, VCard>
